import { Breadcrumbs, makeStyles } from '@material-ui/core'
import UserApi from 'api/user'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import MainLayout from 'layouts/MainLayout'
import useAbstractProvider from 'providers/AbstractProvider'
import React, { useContext, useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import Text from 'components/atoms/Text/Text'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Loader from 'components/atoms/Loader/Loader'
import { StoreContext } from 'App'
import { useTranslation } from 'react-i18next'
import ChannelsApi from 'api/channels'
import traineeHelpers from 'helpers/trainee'
import useAbstractMutator from 'providers/AbstractMutator'
import NotificationsApi from 'api/notifications'
import TraineeDetailsTable from './TraineeDetailsTable'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 25,
    height: '100%',
    maxHeight: '100%',
    paddingBottom: 50,
  },
  heading: {
    marginBottom: 15,
  },
  traineeChannelName: {
    margin: '0.5rem 0 1rem 0',
  },
  name: {
    color: '#333333',
  },
  breadcrumbs: {
    marginBottom: 25,
  },
  table: {
  },
  breadcrumbsPrimary: {
    fontWeight: 600,
    color: theme.palette.primary.light,
  },
  breadcrumbsSecondary: {
    fontWeight: 300,
    color: theme.palette.text.primary,
  },
}))

const MentorsUserDetailPage = () => {
  const store = useContext(StoreContext)
  const classes = useStyles()
  const { t } = useTranslation()
  const [openProductId, setOpenProductId] = React.useState(0)
  const [loading, setLoading] = useState(true)
  const { userId, channelId, argumentationId, recordingId } = useParams<any>()
  const [paramArgumentation, setParamArgumentation] = useState(null)
  const [paramRecording, setParamRecording] = useState(null)
  const [modalType, setModalType] = useState(null)

  const { data: getProductNotificationsData, refetch: getProductNotifications } =
    useAbstractProvider(NotificationsApi.getProductNotifications, null, false)

  useEffect(() => {
    if (openProductId) {
      getProductNotifications({ productId: openProductId, traineeId: userId })
    }
  }, [openProductId])

  const { data: getTeamUserDetailData, refetch } = useAbstractProvider(
    UserApi.getTeamUserDetailData,
    { userId, channelId },
  )
  const channelProvider = useAbstractProvider(ChannelsApi.getChannelById, {
    channelId,
    query: 'products,conversationGroups',
  })

  const { data: requestTrainingNotificationData, mutate: requestTrainingNotification } =
    useAbstractMutator(NotificationsApi.requestTrainingNotification)

  const {
    data: disableRequestTrainingNotificationData,
    mutate: disableRequestTrainingNotification,
  } = useAbstractMutator(NotificationsApi.disableRequestTrainingNotification)

  const [data, setData] = useState([])

  useEffect(() => {
    if (requestTrainingNotificationData) {
      getProductNotifications({ productId: openProductId, traineeId: userId })
    }
  }, [requestTrainingNotificationData])

  useEffect(() => {
    if (disableRequestTrainingNotificationData) {
      getProductNotifications({ productId: openProductId, traineeId: userId })
    }
  }, [disableRequestTrainingNotificationData])

  useEffect(() => {
    if (channelProvider?.data && getTeamUserDetailData) {
      setTimeout(() => {
        setLoading(false)
      }, 800)
      setData(
        traineeHelpers.processTraineeDataInChannel({
          channelData: channelProvider?.data,
          traineeData: getTeamUserDetailData,
        }),
      )
    }
  }, [channelProvider?.data, getTeamUserDetailData])

  useEffect(() => {
    if (getTeamUserDetailData && channelId && argumentationId) {
      const argumentation = getTeamUserDetailData?.argumentations.find(
        (item) => parseInt(item.id, 10) === parseInt(argumentationId, 10),
      )

      setParamArgumentation(argumentation)
      setModalType('argumentation')
    }
  }, [channelId, argumentationId, getTeamUserDetailData])

  useEffect(() => {
    if (getTeamUserDetailData && channelId && recordingId) {
      const recording = getTeamUserDetailData?.recordings.find(
        (item) => parseInt(item.id, 10) === parseInt(recordingId, 10),
      )

      setParamRecording(recording)
      setModalType('recording')
    }
  }, [channelId, recordingId, getTeamUserDetailData])

  return (
    <ProtectedPage>
      {MainLayout.getLayout(
        <div className={classes.container}>
          <Text variant="h2" className={classes.heading}>
            {t('mentor_user_detail.heading')}
          </Text>
          <Breadcrumbs
            className={classes.breadcrumbs}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Text variant="body1" className={classes.breadcrumbsPrimary}>
              {t('user')}:{' '}
              <Link to="/mentors-view/user-detail/userId=1">
                <span className={classes.breadcrumbsSecondary}>
                  {getTeamUserDetailData?.user.firstName} {getTeamUserDetailData?.user.lastName}
                </span>
              </Link>
            </Text>
            <Text variant="body1" className={classes.breadcrumbsPrimary}>
              {t('channel')}:{' '}
              <span className={classes.breadcrumbsSecondary}>{channelProvider?.data?.name}</span>
            </Text>
          </Breadcrumbs>
          {loading ? (
            <Loader />
          ) : (
            <>
              <div className={classes.table}>
                <Text variant="h3">{t('product')}</Text>
                {getTeamUserDetailData && (
                  <TraineeDetailsTable
                    data={data}
                    refetch={() => {
                      setLoading(true)

                      refetch({ userId, channelId })
                    }}
                    openProductId={openProductId}
                    modalTypeOpen={modalType}
                    paramArgumentation={paramArgumentation}
                    paramRecording={paramRecording}
                    setOpenProductId={setOpenProductId}
                    mentorView
                    trainee={getTeamUserDetailData?.user}
                    requestTrainingNotification={requestTrainingNotification}
                    disableRequestTrainingNotification={disableRequestTrainingNotification}
                    productNotifications={getProductNotificationsData}
                    channel={channelProvider?.data}
                  />
                )}
              </div>
            </>
          )}
        </div>,
      )}
    </ProtectedPage>
  )
}

export default MentorsUserDetailPage
