/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect } from 'react'
import { getLocalStorage, setLocalStorage } from 'utils'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { StoreContext } from 'App'
import ConversationsProgressApi from 'api/conversationProgress'
import ProductsApi from 'api/products'
import ChannelsApi from 'api/channels'
import UserApi from 'api/user'
import ConfigsApi from 'api/configs'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import initContentSecurityPolicy from 'helpers/initContentSecurityPolicy'
import LearningTypesApi from 'api/learningTypes'
import useAbstractProvider from '../providers/AbstractProvider'
import AvatarsApi from '../api/avatars'
import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/it'

interface ParamsProps {
  channelId?: string
  productId?: string
}

function InitStore() {
  const store = useContext(StoreContext)
  // const history = useHistory()
  const { channelId, productId } = useParams<ParamsProps>()

  const { data: avatars } = useAbstractProvider(AvatarsApi.getAvatars)
  const { data: productPhases, refetch: refetchProductPhases } = useAbstractProvider(
    ProductsApi.getProductPhases,
    null,
    false,
  )
  const { data: conversationsProgress, refetch: refetchConversationsProgress } =
    useAbstractProvider(ConversationsProgressApi.getConversationsProgress, null, false)
  const { data: productsByChannelId, refetch: getProductsByChannelId } = useAbstractProvider(
    ProductsApi.getProductsByChannelId,
    {},
    false,
  )
  const { data: currentlyLoggedInUser } = useAbstractProvider(UserApi.getCurrentlyLoggedInUser)

  const { data: cloudfrontUrl } = useAbstractProvider(ConfigsApi.getCloudfrontUrl)

  const { data: channels } = useAbstractProvider(ChannelsApi.getChannels)

  const { data: learningTypes } = useAbstractProvider(LearningTypesApi.getLearningTypes)

  const { i18n } = useTranslation()

  moment.locale(i18n.resolvedLanguage)

  useEffect(() => {
    if (cloudfrontUrl) {
      initContentSecurityPolicy(cloudfrontUrl)
      store.setCloudfrontUrl(cloudfrontUrl)
    }
  }, [cloudfrontUrl])

  useEffect(() => {
    if (avatars) {
      store.setAvatars(avatars)
    }
  }, [avatars])


  useEffect(() => {
    if (learningTypes) {
      store.setLearningTypes(learningTypes)
    }
  }, [learningTypes])

  useEffect(() => {
    if (store?.conversationsProgress?.length < 1 && store?.selectedProductCategory?.id) {
      refetchConversationsProgress(store?.selectedProductCategory?.id)
    }
  }, [store?.selectedProductCategory])

  useEffect(() => {
    if (conversationsProgress) {
      store.setConversationsProgress(conversationsProgress)
    }
  }, [conversationsProgress])

  useEffect(() => {
    if (store?.selectedProductCategory?.id) {
      refetchProductPhases(store.selectedProductCategory.id)
    }
  }, [store?.selectedProductCategory])

  useEffect(() => {
    if (productPhases) {
      store.setProductPhases(productPhases)
      if (!store.initialPhases) {
        store.setPhase(productPhases[0].id)
      } else {
        store.setInitialPhases(false)
      }
    }
  }, [productPhases])

  useEffect(() => {
    if (store?.products?.length < 1 && store?.selectedChannel?.id) {
      getProductsByChannelId(store?.selectedChannel?.id)
    }
  }, [store?.selectedChannel?.id])

  useEffect(() => {
    if (productsByChannelId) {
      store.setProducts(productsByChannelId)

      if (
        store.selectedProductCategory?.id &&
        !productsByChannelId.find((product) => product.id === store.selectedProductCategory?.id)
      ) {
        store.setSelectedProductCategory(productsByChannelId[0].id)
      }
    }
  }, [productsByChannelId])

  useEffect(() => {
    const cookieAvatar = getLocalStorage('selectedAvatar')
    store.setSelectedAvatar(parseInt(cookieAvatar, 10))
  }, [store?.avatars])

  useEffect(() => {
    setLocalStorage('selectedPhase', store.phase)
  }, [store?.phase])

  useEffect(() => {
    setLocalStorage('selectedGroup', store.selectedGroup)
  }, [store?.selectedGroup])

  useEffect(() => {
    setLocalStorage('selectedConversation', store.selectedConversation)
  }, [store?.selectedConversation])

  useEffect(() => {
    setLocalStorage('selectedView', store.selectedView)
  }, [store?.selectedView])

  useEffect(() => {
    const cookieProductCategory = getLocalStorage('selectedProductCategory')

    if (productId) {
      const foundProduct = store.products.find((product) => product.id === parseInt(productId, 10))

      if (foundProduct) {
        setLocalStorage('selectedProductCategory', foundProduct.id)
        store.setSelectedProductCategory(parseInt(foundProduct.id, 10))
      }
    } else if (
      cookieProductCategory &&
      store.products.length > 0 &&
      store.selectedProductCategory?.id !== parseInt(cookieProductCategory, 10)
    ) {
      store.setSelectedProductCategory(parseInt(cookieProductCategory, 10))
    } else if (store?.products.length > 0) {
      store.setSelectedProductCategory(store?.products[0].id)
    }
  }, [store?.products, productId])

  useEffect(() => {
    if (channels) {
      store.setChannels(channels)
    }
  }, [channels])

  useEffect(() => {
    if (channelId) {
      setLocalStorage('selectedChannel', channelId)
      store.setSelectedChannel(parseInt(channelId, 10))
    } else {
      const cookieChannelId = getLocalStorage('selectedChannel')
      store.setSelectedChannel(parseInt(cookieChannelId, 10))
    }
  }, [channelId, channels])

  useEffect(() => {
    if (currentlyLoggedInUser) {
      store.setCurrentUser(currentlyLoggedInUser)
    } else {
      // history.push('sign-in')
    }
  }, [currentlyLoggedInUser])

  return <div />
}

export default observer(InitStore)
