import { Grid, List, ListItem, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React, { useState, useEffect, useContext } from 'react'

import ArgumentationTemplatesApi from 'api/argumentationTemplates'
import ArgumentationsApi from 'api/argumentations'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import Text from 'components/atoms/Text/Text'
import { CustomModal } from 'components/organisms/MaterialModal/MaterialModal'
import useMedia from 'hooks/useMedia'
import useAbstractMutator from 'providers/AbstractMutator'
import useAbstractProvider from 'providers/AbstractProvider'
import CheckIcon from '@material-ui/icons/Check'
import { StoreContext } from 'App'
import { useTranslation } from 'react-i18next'
import ConfirmModal from 'components/atoms/ConfirmModal/ConfirmModal'
import { showToast } from 'utils'
import SendIcon from '@material-ui/icons/Send'
import PostAddIcon from '@material-ui/icons/PostAdd'
import HtmlEditor from '../HtmlEditor/HtmlEditor'
import CustomBreadcrumbs from '../CustomBreadcrumbs/CustomBreadcrumbs'
import CancelButton from '../../atoms/ColorButton/CancelButton'

export interface ArgumentationModalProps {
  modalStatus: boolean
  setModalStatus: Function
  conversationGroupId: number
  conversationGroupName: string
  phase: any
}

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 150,
  },
  centerBoth: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    padding: 30,
  },
  stepText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  stepContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 10px'
  },
  step: {
    backgroundColor: '#f07662',
    width: '60px',
    height: '60px',
    borderRadius: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  },
  stepNumber: {
    color: '#fff',
    fontSize: '40px',
  },
  title: {
    marginBottom: 30,
  },
  editor: {
    marginBottom: 20,
  },
  listItem: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  list: {
    paddingLeft: 50,
    maxHeight: 400,
  },
  newArgBtn: {
    marginLeft: 50,
  },
  saveBtn: {
    marginRight: '1.25rem',
  },
  cancelBtn: {
    marginTop: '1rem',
  },
  cancelBtnDesktop: {
    marginTop: 0,
  },
  publishBtn: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
  btns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  scroll: {
    overflow: 'scroll',
    marginTop: '80px',
  },
  groupTitle: {
    marginBottom: 10,
  },
  published: {
    display: 'flex',
    alignItems: 'center',
    color: '#41B883',
  },
  checkbox: {
    marginRight: 5,
  },
  argumentationTitle: {
    '& .MuiListItemText-primary': {
      fontWeight: 'bold',
    },
  },
}))

type ArgumentationType = {
  id?: number
  text?: string
  published?: boolean
}

const ArgumentationModal: React.SFC<ArgumentationModalProps> = ({
  modalStatus,
  setModalStatus,
  conversationGroupId,
  conversationGroupName,
  phase,
}) => {
  const store = useContext(StoreContext)
  const [editorValue, setEditorValue] = useState('')
  const [editorDefaultValue, setDefaultEditorValue] = React.useState('')
  const { data: argumentations, refetch: refetchArgumentations } = useAbstractProvider(
    ArgumentationsApi.getArgumentations,
    { conversationGroupId },
    false,
  )

  const [deleteConfirmModalStatus, setDeleteConfirmModalStatus] = useState(false)

  const {
    data: saveArgumentationData,
    mutate: saveArgumentationMutation,
    error: saveArgumentationError,
  } = useAbstractMutator(ArgumentationsApi.saveArgumentation)
  const {
    data: editArgumentationData,
    mutate: editArgumentationMutation,
    error: editArgumentationError,
  } = useAbstractMutator(
    ArgumentationsApi.editArgumentation,
  )
  const {
    data: deleteArgumentationData,
    mutate: deleteArgumentationMutation,
    error: deleteArgumentationError,
  } = useAbstractMutator(
    ArgumentationsApi.deleteArgumentation,
  )
  const { data: argumentationTemplate, refetch: refetchArgumentationTemplate } =
    useAbstractProvider(
      ArgumentationTemplatesApi.getArgumentationTemplateByGroudId,
      {
        conversationGroupId,
      },
      false,
    )

  const [argumentation, setArgumentation] = useState<ArgumentationType>({
    text: '',
  })

  const { t } = useTranslation()

  const classes = useStyles()
  function toggleModal(value) {
    setModalStatus(value)
  }

  useEffect(() => {
    if (argumentations?.length > 0) {
      setArgumentation(argumentations[argumentations.length - 1])
      setEditorValue(argumentations[argumentations.length - 1]?.text)
      setDefaultEditorValue(argumentations[argumentations.length - 1]?.text)
    } else {
      setEditorValue(argumentationTemplate?.text || '')
      setDefaultEditorValue(argumentationTemplate?.text || '')
      setArgumentation({ text: '' })
    }
  }, [argumentations, conversationGroupId, argumentationTemplate])

  useEffect(() => {
    if (saveArgumentationError) {
      showToast(saveArgumentationError.data.message)
    }
  }, [saveArgumentationError])

  useEffect(() => {
    if (editArgumentationError) {
      showToast(editArgumentationError.data.message)
    }
  }, [editArgumentationError])

  useEffect(() => {
    if (deleteArgumentationError) {
      showToast(deleteArgumentationError.data.message)
    }
  }, [deleteArgumentationError])


  useEffect(() => {
    if (!conversationGroupId) return
    refetchArgumentations(conversationGroupId)
  }, [conversationGroupId, saveArgumentationData, deleteArgumentationData, editArgumentationData])

  useEffect(() => {
    if (!conversationGroupId || !saveArgumentationData) return
    refetchArgumentations(conversationGroupId)
  }, [conversationGroupId, saveArgumentationData])

  useEffect(() => {
    if (!conversationGroupId) return
    refetchArgumentationTemplate(conversationGroupId)
  }, [conversationGroupId])

  useEffect(() => {
    if (deleteArgumentationData) {
      setArgumentation({})
    }
  }, [deleteArgumentationData])

  function saveArgumentation() {
    if (argumentation.id) {
      const newArgumentation = {
        id: argumentation.id,
        text: editorValue,
        published: argumentation.published,
        languageCode: store.language,
      }
      editArgumentationMutation(newArgumentation)
    } else {
      const newArgumentation = {
        conversationGroupId,
        text: editorValue,
        languageCode: store.language,
      }
      saveArgumentationMutation(newArgumentation)
    }
  }

  function publishArgumentation() {
    editArgumentationMutation({ id: argumentation.id, published: true })
  }

  function deleteArgumentationConfirm() {
    setDeleteConfirmModalStatus(true)
  }

  function deleteArgumentation() {
    if (argumentation.id) {
      deleteArgumentationMutation(argumentation.id)
    } else {
      setArgumentation({})
      setEditorValue(argumentationTemplate?.text)
      setDefaultEditorValue(argumentationTemplate?.text)
    }
  }

  function selectArgumentation(argumentationId) {
    const selectedArgumentation = argumentations.find((argum) => argum.id === argumentationId)
    setArgumentation(selectedArgumentation)
    setEditorValue(selectedArgumentation.text)
    setDefaultEditorValue(selectedArgumentation.text)
  }

  function addArgumentation() {
    const newArgumentation = {
      conversationGroupId,
      text: argumentationTemplate?.text || '',
      languageCode: store.language,
    }
    saveArgumentationMutation(newArgumentation)
  }

  const { md: isDesktop } = useMedia()

  return (
    <CustomModal
      open={modalStatus}
      toggleModal={toggleModal}
      header={
        <div className={classes.stepContainer}>
          <CustomBreadcrumbs disableLinks/>
        </div>
      }
      body={
        <div className={classes.modalContainer}>
          <Grid container>
            <Grid item sm={8}>
              <Text variant="h3" className={classes.title}>
                {t('learning.my_arguments_and_screenplay')}
              </Text>
              <Text variant="h4" className={classes.groupTitle}>
                {conversationGroupName}
              </Text>
              <HtmlEditor
                className={classes.editor}
                defaultValue={editorDefaultValue}
                value={editorValue}
                onChange={setEditorValue}
              />
              <div className={classes.btns}>
                <div>
                  <CustomButton
                    className={classes.saveBtn}
                    color="primary"
                    onClick={() => saveArgumentation()}
                  >
                    {t('save')}
                  </CustomButton>
                  <CancelButton
                    variant="outlined"
                    onClick={() => deleteArgumentationConfirm()}
                  >
                    {t('delete')}
                  </CancelButton>
                </div>
                {argumentation?.published ? (
                  <Text variant="body1" className={classes.published}>
                    <CheckIcon className={classes.checkbox} /> {t('published')}
                  </Text>
                ) : (
                  <CustomButton
                    startIcon={<SendIcon/>}
                    onClick={() => publishArgumentation()}
                    className={classNames(classes.publishBtn)}
                  >{t('publish')}
                  </CustomButton>
                )}
              </div>
            </Grid>
            {isDesktop && (
              <Grid className={classes.scroll} item sm={4}>
                <List className={classes.list} component="nav" aria-label="main mailbox folders">
                  {argumentations?.map((argum, index) => (
                    <ListItem
                      key={argum.id}
                      selected={argumentation.id === argum.id}
                      button
                      onClick={() => selectArgumentation(argum.id)}
                    >
                      <ListItemText
                        className={classes.argumentationTitle}
                        primaryTypographyProps={{ variant: 'body2' }}
                        primary={`${t('argumentation')} #${index + 1}`}
                        secondary={argum.published ? t('published') : t('draft')}
                      />
                    </ListItem>
                  ))}
                </List>
                <CustomButton
                  className={classes.newArgBtn}
                  onClick={() => addArgumentation()}
                  startIcon={<PostAddIcon/>}
                >{t('learning.add_new_argumentation')}</CustomButton>
              </Grid>
            )}
          </Grid>
          <ConfirmModal
            open={deleteConfirmModalStatus}
            toggleModal={() => setDeleteConfirmModalStatus(!deleteConfirmModalStatus)}
            message={t('delete_confirm')}
            confirmHandler={() => deleteArgumentation()}
          />
        </div>
      }
    />
  )
}

export default ArgumentationModal
