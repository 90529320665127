import { Grid, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import Text from 'components/atoms/Text/Text'
import { CustomModal } from 'components/organisms/MaterialModal/MaterialModal'
import { useTranslation } from 'react-i18next'
import CancelButton from 'components/atoms/ColorButton/CancelButton'
import NotificationActiveIcon from '@material-ui/icons/NotificationsActive'
import moment from 'moment'

export interface DisableRequestTrainingModalProps {
  modalStatus: boolean
  setModalStatus: Function
  onClose?: Function
  disableRequestTrainingNotification: Function
  productNotification: any
}

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: '20px 70px 20px 20px',
  },
  title: {
    marginBottom: 0,
  },
  editor: {
    marginBottom: 20,
  },
  saveBtn: {
    marginRight: '1.25rem',
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
    },
  },
  cancelBtn: {
    backgroundColor: '#999999',
  },
  btns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: '2rem',
  },
  container: {
    padding: '20px 0px 20px 0px',
  },
}))

const DisableRequestTrainingModal: React.SFC<DisableRequestTrainingModalProps> = ({
  modalStatus,
  setModalStatus,
  onClose,
  disableRequestTrainingNotification,
  productNotification,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()

  function toggleModal(value) {
    if (!value && onClose) {
      onClose()
    }
    setModalStatus(value)
  }

  return (
    <CustomModal
      open={modalStatus}
      maxWidth="md"
      toggleModal={toggleModal}
      header={
        <Grid container spacing={4} className={classes.container}>
          <Grid item xs={12}>
            <Text variant="h3" className={classes.title}>
              <NotificationActiveIcon
                style={{ cursor: 'pointer', color: theme.palette.warning.main, marginRight: 5 }}
                fontSize="small"
              />
              {t('reminder')}
            </Text>
          </Grid>
        </Grid>
      }
      body={
        <div className={classes.modalContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <div>
                {t('request_training_notification_text', {
                  date: productNotification?.created
                    ? moment(productNotification?.created).format('DD. MMMM YYYY')
                    : 'N/A',
                  weeks: Math.round(productNotification?.repeatInterval / (60 * 24 * 7)),
                  remaining: productNotification?.repeatCount - productNotification?.sentCount,
                })}
              </div>
              <div className={classes.btns}>
                <div>
                  <CustomButton
                    className={classes.saveBtn}
                    color="success"
                    onClick={() => {
                      console.log(
                        'productNotification.notificationId: ',
                        productNotification.notificationId,
                      )
                      disableRequestTrainingNotification({
                        id: productNotification.notificationId,
                      })
                      setModalStatus(false)
                    }}
                  >
                    {t('deactivate')}
                  </CustomButton>
                  <CancelButton
                    variant="outlined"
                    onClick={() => {
                      setModalStatus(false)
                    }}
                  >
                    {t('cancel')}
                  </CancelButton>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      }
    />
  )
}

export default DisableRequestTrainingModal
