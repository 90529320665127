import React, { FC, useEffect, useState } from 'react'
import StaticContentsApi from 'api/staticContents'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import Text from 'components/atoms/Text/Text'
import { Grid, makeStyles } from '@material-ui/core'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import useAbstractProvider from 'providers/AbstractProvider'
import Loader from 'components/atoms/Loader/Loader'
import MaterialTable from 'components/organisms/MaterialTable/MaterialTable'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AdminLayout from 'layouts/AdminLayout'

const useStyles = makeStyles({
  heading: {
    marginBottom: 45,
  },
  saveBtn: {
    marginTop: 15,
  },
  search: {
    marginBottom: 15,
  },
})

interface StaticContentsPageProps {}

const StaticContentsPage: FC<StaticContentsPageProps> = () => {
  const classes = useStyles()
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)
  const history = useHistory()

  const staticContents = useAbstractProvider(StaticContentsApi.getAll, { search, offset, limit })
  const { t } = useTranslation()

  useEffect(() => {
    if (staticContents?.data) {
      setData(staticContents?.data.data)
    }
  }, [staticContents.data])

  useEffect(() => {
    staticContents.refetch({ search, offset, limit })
  }, [search, offset, limit])

  function handleChangePage(pageToChange) {
    setOffset(pageToChange * limit)
  }

  const selectItem = (selectedItem) => {
    history.push(`/admin-view/static-contents/${selectedItem.id}`)
  }

  const head = [
    { name: t('id'), dbName: 'id' },
    { name: t('code'), dbName: 'code' },
    { name: t('static_content.name'), dbName: 'name' },
    { name: t('last_modification'), dbName: 'lastModification', type: 'datetime' },
  ]

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <Grid container>
            <Grid item xs={6}>
              <Text variant="h2" className={classes.heading}>
                {t('admin_static_contents.heading')}
              </Text>
            </Grid>
            <Grid item xs={12}>
              <MaterialInput
                className={classes.search}
                type="text"
                label={t('search')}
                name="search"
                value={search}
                onChange={setSearch}
              />
            </Grid>

            <Grid item xs={12}>
              {staticContents.loading ? (
                <Loader />
              ) : (
                <div>
                  <MaterialTable
                    head={head}
                    data={data}
                    onRowClick={selectItem}
                    count={staticContents?.data?.count}
                    onChangePage={handleChangePage}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={limit}
                    onChangeRowsPerPage={(value) => {
                      setLimit(value)
                      setOffset(0)
                    }}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </>,
      )}
    </ProtectedPage>
  )
}

export default StaticContentsPage
