import MentorsUserDetailPage from 'pages/MentorsPage/MentorsUserDetailPage'
import React from 'react'
import { Route as ReactRoute } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import MentorChannels from 'pages/MentorsPage/MentorChannels'
import StatisticsDashboard from './pages/MentorsPage/StatisticsDashboard'
import TraineeDashboard from './pages/MentorsPage/TraineeDashboard'

const Route = Sentry.withSentryRouting(ReactRoute)

const MentorsRoutes = () => (
  <>
    <Route exact path="/mentors-view/user-detail/userId=:userId/channelId=:channelId/argumentationId=:argumentationId">
      <MentorsUserDetailPage />
    </Route>
    <Route exact path="/mentors-view/user-detail/userId=:userId/channelId=:channelId/recordingId=:recordingId">
      <MentorsUserDetailPage />
    </Route>
    <Route exact path="/mentors-view/user-detail/userId=:userId/channelId=:channelId">
      <MentorsUserDetailPage />
    </Route>
    <Route exact path="/mentors-view/user-detail/userId=:userId">
      <MentorChannels />
    </Route>
    <Route exact path="/mentors-view">
      <TraineeDashboard />
    </Route>
    <Route exact path="/manager-view">
      <StatisticsDashboard />
    </Route>
  </>
)

export default MentorsRoutes
