import { BACKEND_URL, SENTRY_DSN } from './configuration'

let ADDITIONAL_URLS =
  'https://ipv4.icanhazip.com/ https://api.ipify.org/ https://cdnjs.cloudflare.com/ https://www.google.com https://www.gstatic.com/recaptcha/ https://cdn.tiny.cloud https://sp.tinymce.com/'

if (SENTRY_DSN) {
  try {
    const sentryOriginUrl = (new URL(SENTRY_DSN)).origin
    ADDITIONAL_URLS = `${ADDITIONAL_URLS} ${sentryOriginUrl}`
  } catch (e) {
    console.log('Sentry DSN invalid: ', SENTRY_DSN)
  }
}

const initContentSecurityPolicy = (cloudfrontUrl = '') => {
  const meta = document.createElement('meta')
  meta.httpEquiv = 'Content-Security-Policy'
  meta.content = `
      default-src ${BACKEND_URL} ws: wss:  ${ADDITIONAL_URLS} ${cloudfrontUrl} 'self' 'unsafe-inline' blob:;
      connect-src ${BACKEND_URL} ws: wss: ${ADDITIONAL_URLS} ${cloudfrontUrl} 'self' 'unsafe-inline' blob:;
      img-src ${cloudfrontUrl} ${BACKEND_URL} https://sp.tinymce.com/ 'self' data: blob:; 
      worker-src blob: 'self';
      child-src blob: https://www.google.com/;
    `
  document.getElementsByTagName('head')[0].appendChild(meta)
}

export default initContentSecurityPolicy
