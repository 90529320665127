import { makeStyles } from '@material-ui/core'
import React, { ReactElement } from 'react'

import Footer from 'components/organisms/Footer/Footer'
import Header from 'components/organisms/Header/Header'
import LearningBottomNavigation from 'components/learning/LearningBottomNavigation'
import useMedia from 'hooks/useMedia'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles({
  mainLayoutContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  outerContent: {
    minHeight: 'calc(100vh - 3.75rem - 4rem)',
    margin: '0px auto',
    overflowX: 'hidden',
    overflowY: 'auto',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  content: {
    width: '100%',
    padding: '0px 20px',
    maxWidth: '1320px',
    '@media (max-width: 600px)': {
      padding: '0px 16px',
    },
  },
})

const MainLayout = ({ children }) => {
  const classes = useStyles()
  const { md: isDesktop, isLoading: isMediaLoading } = useMedia()
  const location = useLocation()
  const path = location.pathname
  const foundLearn = path.split('/')[1] === 'learn'

  return (
    <div className={classes.mainLayoutContainer}>
      <Header />
      <div className={classes.outerContent}>
        <div className={classes.content}>{children}</div>
      </div>
      {!isDesktop && foundLearn && !isMediaLoading ? <LearningBottomNavigation /> : null}
      <Footer />
    </div>
  )
}

MainLayout.getLayout = (page): ReactElement => <MainLayout>{page}</MainLayout>

export default MainLayout
