import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from 'App'
import { makeStyles } from '@material-ui/core'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import MainLayout from 'layouts/MainLayout'
import Text from 'components/atoms/Text/Text'
import { useTranslation } from 'react-i18next'
import ChannelSelector from 'components/organisms/ChannelSelector/ChannelSelector'
import useAbstractProvider from 'providers/AbstractProvider'
import UserApi from 'api/user'
import ChannelsApi from 'api/channels'
import traineeHelpers from 'helpers/trainee'
import Loader from 'components/atoms/Loader/Loader'
import { useHistory, useParams } from 'react-router-dom'
import TraineeDetailsTable from './MentorsPage/TraineeDetailsTable'

const useStyles = makeStyles({
  container: {
    paddingTop: 25,
    height: '100%',
    maxHeight: '100%',
  },
  heading: {
    marginBottom: 15,
  },
  script: {
    overflow: 'scroll',
  },
  modalContainer: {
    padding: 20,
  },
  groupTitle: {
    marginBottom: 10,
  },
  title: {
    color: '#333333',
    marginBottom: 16,
  },
  name: {
    color: '#333333',
    marginBottom: 16,
  },
  breadcrumbs: {
    marginBottom: 25,
  },
  table: {
    paddingBottom: 70,
  },
})

const TrainingUserDetailPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const store = useContext(StoreContext) as any
  const { t } = useTranslation()
  const userId = store.currentUser.id
  const { channelId: channelIdParam, argumentationId, recordingId } = useParams<any>()

  const [channelId, setChannelId] = useState<number | undefined>(store.selectedChannel?.id)

  const [openProductId, setOpenProductId] = useState(0)
  const [data, setData] = useState(null)
  const [paramArgumentation, setParamArgumentation] = useState(null)
  const [paramRecording, setParamRecording] = useState(null)
  const [modalType, setModalType] = useState(null)

  const {
    data: getTeamUserDetailData,
    refetch,
    loading,
  } = useAbstractProvider(UserApi.getTeamUserDetailData, { userId, channelId }, !!channelId)
  const channelProvider = useAbstractProvider(
    ChannelsApi.getChannelById,
    {
      channelId,
      query: 'products,conversationGroups',
    },
    !!channelId,
  )

  useEffect(() => {
    if (getTeamUserDetailData && channelIdParam && argumentationId) {
      const argumentation = getTeamUserDetailData?.argumentations.find(
        (item) => parseInt(item.id, 10) === parseInt(argumentationId, 10),
      )

      setParamArgumentation(argumentation)
      setModalType('argumentation')
    }
  }, [channelIdParam, argumentationId, getTeamUserDetailData])

  useEffect(() => {
    if (getTeamUserDetailData && channelIdParam && recordingId) {
      const recording = getTeamUserDetailData?.recordings.find(
        (item) => parseInt(item.id, 10) === parseInt(recordingId, 10),
      )

      setParamRecording(recording)
      setModalType('recording')
    }
  }, [channelIdParam, recordingId, getTeamUserDetailData])

  useEffect(() => {
    if (channelIdParam) {
      setChannelId(channelIdParam)
      refetch({ userId, channelId: channelIdParam })
    }
  }, [channelIdParam])

  useEffect(() => {
    if (store.selectedChannel?.id) {
      setChannelId(store.selectedChannel.id)
      refetch({ userId, channelId: store.selectedChannel.id })
      channelProvider.refetch({
        channelId: store.selectedChannel.id,
        query: 'products,conversationGroups',
      })
    }
  }, [store.selectedChannel?.id])

  useEffect(() => {
    if (channelProvider?.data && getTeamUserDetailData) {
      setData(
        traineeHelpers.processTraineeDataInChannel({
          channelData: channelProvider?.data,
          traineeData: getTeamUserDetailData,
        }),
      )
    }
  }, [channelProvider?.data, getTeamUserDetailData])

  useEffect(() => {
    if (channelId) {
      refetch({ userId, channelId })
      channelProvider.refetch({
        channelId,
        query: 'products,conversationGroups',
      })
    }
  }, [channelId])

  return (
    <ProtectedPage>
      {MainLayout.getLayout(
        <div className={classes.container}>
          <Text variant="h2" className={classes.heading}>
            {t('training_user_detail.heading')}
          </Text>
          <ChannelSelector
            onChange={(selectedChannel) => {
              setChannelId(selectedChannel.id)
              store.setSelectedChannel(selectedChannel.id)
              history.push(`/dashboard/channelId=${selectedChannel?.id}`)
            }}
            showSpecificLanguageChannels
          />
          <br />
          {/* eslint-disable-next-line no-nested-ternary */}
          {!channelId ? null : channelProvider.loading || loading ? (
            <Loader />
          ) : (
            data && (
              <TraineeDetailsTable
                data={data}
                refetch={() => refetch({ userId, channelId })}
                openProductId={openProductId}
                setOpenProductId={setOpenProductId}
                modalTypeOpen={modalType}
                paramArgumentation={paramArgumentation}
                paramRecording={paramRecording}
                mentorView={false}
                channel={channelProvider.data}
              />
            )
          )}
        </div>,
      )}
    </ProtectedPage>
  )
}

export default TrainingUserDetailPage
