import { Accordion, AccordionSummary, Checkbox, Grid, makeStyles } from '@material-ui/core'
import Text from 'components/atoms/Text/Text'
import * as React from 'react'
import DescriptionIcon from '@material-ui/icons/Description'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import ArgumentationFeedbackModal from 'components/organisms/ArgumentationFeedbackModal/ArgumentationFeedbackModal'
import RecordingFeedbackModal from 'components/organisms/RecordingFeedbackModal/RecordingFeedbackModal'
import useAbstractMutator from 'providers/AbstractMutator'
import userApprovals from 'api/userApprovals'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { ExpandMore } from '@material-ui/icons'
import TraineeDetailsTableHeader from './TraineeDetailsTableHeader'

const useStyles = makeStyles((theme) => ({
  expandIcon: {
    alignSelf: 'flex-start',
  },
}))

export default function TraineeDetailsTable({
  data,
  refetch,
  openProductId,
  setOpenProductId,
  paramArgumentation,
  paramRecording,
  modalTypeOpen,
  mentorView,
  trainee,
  channel,
  requestTrainingNotification,
  disableRequestTrainingNotification,
  productNotifications,
}: {
  data: any[]
  refetch: Function
  openProductId: number
  setOpenProductId: Function
  modalTypeOpen?: string
  paramArgumentation?: any
  paramRecording?: any
  mentorView: boolean
  trainee?: any
  channel: any
  requestTrainingNotification?: Function
  disableRequestTrainingNotification?: Function
  productNotifications?: any
}) {
  const history = useHistory()
  const classes = useStyles()
  const { userId } = useParams<{ userId: string }>()
  const [argumentationFeedbackModalStatus, setArgumentationFeedbackModalStatus] =
    React.useState(null)
  const [recordingFeedbackModalStatus, setRecordingFeedbackModalStatus] = React.useState(null)
  const [selectedArgumentation, setSelectedArgumentation] = React.useState<any>()
  const [selectedRecording, setSelectedRecording] = React.useState<any>()

  const [selectedGroup, setSelectedGroup] = React.useState<any>()

  const approveUserMutate = useAbstractMutator(userApprovals.approveUser)
  const rejectUserMutate = useAbstractMutator(userApprovals.rejectUser)

  useEffect(() => {
    if (paramArgumentation) {
      setSelectedArgumentation(paramArgumentation)
    }
  }, [paramArgumentation])

  useEffect(() => {
    if (paramRecording) {
      setSelectedRecording(paramRecording)
    }
  }, [paramRecording])

  useEffect(() => {
    if (modalTypeOpen) {
      if (modalTypeOpen === 'argumentation') {
        setArgumentationFeedbackModalStatus(true)
      }
      if (modalTypeOpen === 'recording') {
        setRecordingFeedbackModalStatus(true)
      }
    }
  }, [modalTypeOpen])

  const onArgumentationClick = (argumentation, group) => {
    setSelectedArgumentation(argumentation)
    setSelectedGroup(group)
    history.push(
      mentorView
        ? `/mentors-view/user-detail/userId=${userId}/channelId=${channel?.id}/argumentationId=${argumentation.id}`
        : `/dashboard/channelId=${channel.id}/argumentationId=${argumentation.id}`,
    )

    setArgumentationFeedbackModalStatus(!argumentationFeedbackModalStatus)
  }

  const onRecordingClick = (recording, group) => {
    setSelectedRecording(recording)
    setSelectedGroup(group)
    history.push(
      mentorView
        ? `/mentors-view/user-detail/userId=${userId}/channelId=${channel.id}/recordingId=${recording.id}`
        : `/dashboard/channelId=${channel.id}/recordingId=${recording.id}`,
    )
    setRecordingFeedbackModalStatus(!recordingFeedbackModalStatus)
  }

  const onArgumentationModalClose = () => {
    if (mentorView) {
      history.push(`/mentors-view/user-detail/userId=${userId}/channelId=${channel.id}`)
    } else {
      history.goBack()
    }
  }

  const handleUserApproval = (
    approvalTypeId: number,
    conversationGroupId: number,
    checked: boolean,
    approvalId?: number,
  ) => {
    if (checked) {
      approveUserMutate.mutate({ approvalTypeId, conversationGroupId, userId })
    } else {
      rejectUserMutate.mutate(approvalId)
    }
    refetch()
  }


  return (
    <>
      <br />
      {data.map((product) => (
        <Accordion key={product.id} expanded={openProductId === product.id}>
          <AccordionSummary
            IconButtonProps={{
              onClick: () => setOpenProductId(openProductId === product.id ? 0 : product.id),
            }}
            expandIcon={<ExpandMore />}
            classes={{
              expandIcon: classes.expandIcon,
            }}
          >
            <Grid
              key={product.id}
              container
              item
              xs={12}
              style={{
                marginBottom: '0.5rem',
              }}
            >
              <div
                onClick={() => setOpenProductId(openProductId === product.id ? 0 : product.id)}
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Text variant="h4">{product.name}</Text>
              </div>
              {openProductId === product.id ? (
                <TraineeDetailsTableHeader
                  trainee={trainee}
                  requestTrainingNotification={requestTrainingNotification}
                  disableRequestTrainingNotification={disableRequestTrainingNotification}
                  product={product}
                  productNotifications={productNotifications}
                  channelName={channel.name}
                  mentorView={mentorView}
                />
              ) : null}
              {product.conversationGroups.map((conversationGroup) => {
                const approval1 = conversationGroup.approvals.find(
                  (item) => item.approvalTypeId === 1,
                )
                const approval2 = conversationGroup.approvals.find(
                  (item) => item.approvalTypeId === 2,
                )
                const approval3 = conversationGroup.approvals.find(
                  (item) => item.approvalTypeId === 3,
                )
                return (
                  <Grid
                    key={conversationGroup.id}
                    item
                    container
                    xs={12}
                    alignItems="center"
                    style={{
                      margin: '0.1rem 0',
                      borderBottom: '1px solid rgba(211, 211, 211, 0.5)',
                      display: openProductId === product.id ? 'inherit' : 'none',
                    }}
                  >
                    <Grid item xs={4}>
                      {conversationGroup.name}
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container item xs={12} alignItems="center">
                        <Grid item xs={6} style={{ display: 'flex' }}>
                          {conversationGroup.argumentations.map((argumentation) => (
                            <p key={argumentation.id} style={{ position: 'relative' }}>
                              <DescriptionIcon
                                onClick={() =>
                                  onArgumentationClick(argumentation, conversationGroup)
                                }
                                style={{ cursor: 'pointer' }}
                                fontSize="small"
                                key={argumentation.id}
                              />
                              <span
                                style={{
                                  position: 'absolute',
                                  backgroundColor:
                                    argumentation.argumentationFeedback?.length > 0
                                      ? '#87D498'
                                      : 'red',
                                  borderRadius: '50%',
                                  width: 7,
                                  height: 7,
                                  top: 0,
                                  right: 0,
                                }}
                              />
                            </p>
                          ))}
                        </Grid>
                        <Grid item xs={3}>
                          <Checkbox
                            onChange={(e, checked) =>
                              handleUserApproval(1, conversationGroup.id, checked, approval1?.id)
                            }
                            style={{ cursor: 'pointer', color: approval1 ? '#87D498' : 'gray' }}
                            size="small"
                            defaultChecked={approval1}
                            disabled={!mentorView}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container item xs={12} alignItems="center">
                        <Grid item xs={6} style={{ display: 'flex' }}>
                          {conversationGroup.recordings.map((recording) => (
                            <p key={recording.id} style={{ position: 'relative' }}>
                              <PlayCircleFilledIcon
                                onClick={() => onRecordingClick(recording, conversationGroup)}
                                style={{ cursor: 'pointer' }}
                                fontSize="small"
                                key={recording.id}
                              />
                              <span
                                style={{
                                  position: 'absolute',
                                  backgroundColor:
                                    recording.recordingFeedback?.length > 0 ? '#87D498' : 'red',
                                  borderRadius: '50%',
                                  width: 7,
                                  height: 7,
                                  top: 0,
                                  right: 0,
                                }}
                              />
                            </p>
                          ))}
                        </Grid>
                        <Grid item xs={3}>
                          <Checkbox
                            onChange={(e, checked) =>
                              handleUserApproval(2, conversationGroup.id, checked, approval2?.id)
                            }
                            size="small"
                            style={{ cursor: 'pointer', color: approval2 ? '#87D498' : 'gray' }}
                            defaultChecked={approval2}
                            disabled={!mentorView}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <Checkbox
                        onChange={(e, checked) =>
                          handleUserApproval(3, conversationGroup.id, checked, approval3?.id)
                        }
                        size="small"
                        style={{ cursor: 'pointer', color: approval3 ? '#87D498' : 'gray' }}
                        defaultChecked={approval3}
                        disabled={!mentorView}
                      />
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </AccordionSummary>
        </Accordion>
      ))}
      <ArgumentationFeedbackModal
        modalStatus={argumentationFeedbackModalStatus}
        setModalStatus={setArgumentationFeedbackModalStatus}
        argumentation={selectedArgumentation}
        group={selectedGroup}
        refetch={refetch}
        enableFeedback={mentorView}
        onClose={onArgumentationModalClose}
      />
      <RecordingFeedbackModal
        modalStatus={recordingFeedbackModalStatus}
        setModalStatus={setRecordingFeedbackModalStatus}
        recording={selectedRecording}
        group={selectedGroup}
        refetch={refetch}
        enableFeedback={mentorView}
        onClose={onArgumentationModalClose}
      />
    </>
  )
}
