import React, { useContext, useEffect, useState } from 'react'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import { Accordion, AccordionSummary, Breadcrumbs, Grid, makeStyles } from '@material-ui/core'
import Text from 'components/atoms/Text/Text'
import { StoreContext } from 'App'
import { useHistory, useParams } from 'react-router-dom'
import FullScreenLoader from 'components/organisms/FullScreenLoader/FullScreenLoader'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import useAbstractProvider from 'providers/AbstractProvider'
import UserApi from 'api/user'
import MainLayout from 'layouts/MainLayout'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 25,
    height: '100%',
    maxHeight: '100%',
    paddingBottom: 50
  },
  heading: {
    marginBottom: 15,
  },
  breadcrumb: {
    color: 'gray',
    fontSize: '1rem',
  },
  buttons: {
    display: 'flex',
    gap: 10,
    justifyContent: 'flex-end',
  },
  accordionHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 15,
  },
  accordionTooltip: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
  },
  accordionName: { width: '90%' },
  accordionDetails: {
    display: 'flex',
    position: 'relative',
  },
  accordionPhotoDiv: {
    marginRight: '5%',
  },
  photoUploadImage: {
    width: 150,
    height: 150,
    objectFit: 'cover',
    borderRadius: '50%',
    margin: '10px 0 0 0',
    boxShadow: '0px -1px 10px 1px rgba(0, 0, 0, 0.4)',
    cursor: 'pointer',
  },
  accordionDataDiv: {
    height: '100%',
  },
  channelEditButtonDiv: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    display: 'flex',
    gap: 5,
  },
  channelEditButton: {
    border: '1px solid lightgray',
    display: 'flex',
    alignItems: 'center',
  },
  languageChooser: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginRight: 20,
  },
  modalContainer: {
    padding: 20,
  },
  breadcrumbs: {
    marginBottom: 25,
  },
  breadcrumbsPrimary: {
    fontWeight: 600,
    color: theme.palette.primary.light,
  },
  breadcrumbsSecondary: {
    fontWeight: 300,
    color: theme.palette.text.primary,
  },
}))

const MentorChannels = () => {
  const store = useContext(StoreContext)
  const { userId } = useParams<any>()

  const { t } = useTranslation()

  const classes = useStyles()
  const history = useHistory()

  const [channels, setChannels] = useState<any[] | undefined>()
  const { data: getUserBydIdData, refetch: getUserBydIdRefetch } = useAbstractProvider(
    UserApi.getUserBydId,
    { userId },
    false,
  )
  console.log('getUserBydIdData: ', getUserBydIdData)

  useEffect(() => {
    if (userId) {
      getUserBydIdRefetch(userId)
    }
  }, [userId])

  useEffect(() => {
    if (store.channels) {
      setChannels(store.channels.filter((item) => item.contentLanguage === store.language))
    }
  }, [store.channels])

  return (
    <ProtectedPage>
      {MainLayout.getLayout(
        <div className={classes.container}>
          <Text variant="h2" className={classes.heading}>
            {t('mentor_user_detail.heading')}
          </Text>
          <Breadcrumbs
            className={classes.breadcrumbs}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Text variant="body1" className={classes.breadcrumbsPrimary}>
              {t('user')}:{' '}
              {getUserBydIdData ? (
                <span className={classes.breadcrumbsSecondary}>
                  {getUserBydIdData.firstName} {getUserBydIdData.lastName}
                </span>
              ) : null}
            </Text>
          </Breadcrumbs>
          {!channels ? (
            <FullScreenLoader />
          ) : (
            <>
              <Grid container>
                <Grid item xs={12}>
                  <Text variant="h3">{t('admin_learning_panel.channels')}</Text>
                </Grid>
                <hr />
                <hr />
                <Grid item xs={12}>
                  {channels.map((channel, index) => (
                    <Accordion key={channel.id}>
                      <AccordionSummary>
                        <div
                          onClick={() => {
                            history.push(
                              `/mentors-view/user-detail/userId=${userId}/channelId=${channel.id}`,
                            )
                          }}
                          className={classes.accordionHeader}
                        >
                          <div className={classes.accordionName}>
                            <Text variant="h4">{channel.name || channel.name}</Text>
                          </div>
                        </div>
                      </AccordionSummary>
                    </Accordion>
                  ))}
                </Grid>
              </Grid>
            </>
          )}
        </div>,
      )}
    </ProtectedPage>
  )
}

export default observer(MentorChannels)
