import React, { useEffect, useRef } from 'react'

const HtmlEditor = ({ value, defaultValue='', onChange, className, readOnly = false, admin = false }) => {
  const editorRef = useRef(null)

  const toolbar = admin
    ? 'undo redo | blocks | ' +
      'bold italic forecolor | alignleft aligncenter ' +
      'alignright alignjustify | bullist numlist outdent indent | code |  ' +
      'removeformat | help'
    : 'undo redo | blocks | ' +
      'bold italic | ' +
      'bullist numlist |  ' +
      'removeformat | help'

  useEffect(() => {
    // @ts-ignore
    window?.tinymce.init({
      selector: 'textarea',
      license_key: 'gpl',
      height: 500,
      menubar: false,
      statusbar: false,
      plugins: [
        'advlist',
        'autolink',
        'lists',
        'link',
        'image',
        'charmap',
        'preview',
        'anchor',
        'searchreplace',
        'visualblocks',
        'code',
        'fullscreen',
        'insertdatetime',
        'media',
        'table',
        'help',
        'wordcount',
      ],
      setup: (editor) => {
        editor.on('change', () => {
          onChange(editor.getContent())
        })
      },
      toolbar,
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    })
    if(readOnly) {
      // @ts-ignore
      window.tinymce.activeEditor.mode.set('readonly')
    }

  }, [])

    // Update editor content when value prop changes
    useEffect(() => {
      // @ts-ignore
      if (window.tinymce && window.tinymce.get(editorRef.current.id) && editorRef && editorRef.current) {
          // @ts-ignore
        if(window.tinymce.get(editorRef.current.id)) {
          try {
            // @ts-ignore
            window.tinymce.get(editorRef.current.id).setContent(defaultValue)

          }
          catch(err) {
            console.log(err)
          }

        }
      }
    }, [defaultValue])

  return <textarea ref={editorRef} value={value}  />
}

export default HtmlEditor
