import React, { useContext, useState } from 'react'
import { Grid, makeStyles, useTheme } from '@material-ui/core'
import Text from 'components/atoms/Text/Text'
import { useTranslation } from 'react-i18next'
import NotificationAddIcon from '@material-ui/icons/AddAlert'
import NotificationActiveIcon from '@material-ui/icons/NotificationsActive'
import RequestTrainingModal from 'components/organisms/RequestTraningModal/RequestTrainingModal'
import DisableRequestTrainingModal from 'components/organisms/DisableRequestTraningModal copy/DisableRequestTrainingModal'
import { observer } from 'mobx-react-lite'
import { StoreContext } from 'App'

const useStyles = makeStyles((theme) => ({
  secondaryText: {
    fontSize: '0.7rem',
    color: theme.palette.primary.main,
  },
  script: {
    overflow: 'scroll',
  },
  modalContainer: {
    padding: '20px 44px 20px 20px',
  },
  groupTitle: {
    marginBottom: 10,
  },
  title: {
    color: '#333333',
    marginBottom: 16,
  },
  editor: {
    marginBottom: 20,
  },
  saveBtn: {
    marginRight: '1.25rem',
  },
  cancelBtn: {
    backgroundColor: '#999999',
    marginTop: '1rem',
  },
  cancelBtnDesktop: {
    marginTop: 0,
  },
  btns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  published: {
    display: 'flex',
    alignItems: 'center',
    color: '#41B883',
  },
  checkbox: {
    marginRight: 5,
  },
  tableHeader: {
    fontWeight: 600,
  },
  heading: {
    fontWeight: 600,
  },
  subheading: {
    color: theme.palette.info.light,
  },
}))

function TraineeDetailsTableHeader({
  trainee,
  requestTrainingNotification,
  disableRequestTrainingNotification,
  product,
  productNotifications,
  channelName,
  mentorView
}: {
  trainee?: any
  requestTrainingNotification: Function
  disableRequestTrainingNotification: Function
  product?: any
  productNotifications?: any
  channelName?: string
  mentorView?: boolean
}) {
  const store = useContext(StoreContext)
  const [requestTrainingModalStatus, setRequestTrainingModalStatus] = useState(false)
  const [disableRequestTrainingModalStatus, setDisableRequestTrainingModalStatus] = useState(false)
  const [selectedLearningType, setSelectedLearningType] = useState()
  const [productNotificationToDisable, setProductNotificationToDisable] = useState()
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const mentorName = `${store?.currentUser?.person?.firstName} ${store?.currentUser?.person?.lastName}`

  return (
    <Grid container alignItems="center">
      <Grid container item xs={4}>
        <Grid item xs={12}>
          <Text variant="h5" className={classes.tableHeader}>
            {t('conversations')}
          </Text>
        </Grid>
      </Grid>
      {store.learningTypes.map((learningType, index) => {
        const productNotification = productNotifications?.find(
          (noti) => noti.learningTypeId === learningType.id,
        )
        return (
          <Grid item xs={index < 2 ? 3 : 2}>
            <Grid container item xs={12}>
              <Grid item xs={12} container alignItems='center'>
                {mentorView ? <div>
                  {productNotification ? (
                    <NotificationActiveIcon
                      onClick={() => {
                        setDisableRequestTrainingModalStatus(true)
                        setProductNotificationToDisable(productNotification)
                      }}
                      style={{ cursor: 'pointer', color: theme.palette.warning.main, marginRight: 5 }}
                      fontSize="small"
                    />
                  ) : (
                    <NotificationAddIcon
                      onClick={() => {
                        setRequestTrainingModalStatus(true)
                        setSelectedLearningType(learningType)
                      }}
                      fontSize="small"
                      style={{ cursor: 'pointer', color: theme.palette.primary.main, marginRight: 5 }}
                    />
                  )}
                </div> : null}

                <Text className={classes.heading} variant="h5">
                  {t(`mentor_dashboard.step_${learningType.id}`)}
                </Text>
              </Grid>
              <Grid item xs={12}>
                <Text className={classes.subheading} variant="h5">
                  {t(`mentor_dashboard.step_${learningType.id}_subheading`)}
                </Text>
              </Grid>
              <Grid item xs={6}>
                <Text className={classes.secondaryText} variant="body1">
                  {t('mentor_dashboard.submitted')}
                </Text>
              </Grid>
              <Grid item xs={3}>
                <Text className={classes.secondaryText} variant="body1">
                  {t('mentor_dashboard.confirmed')}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        )
      })}

      <RequestTrainingModal
        modalStatus={requestTrainingModalStatus}
        setModalStatus={setRequestTrainingModalStatus}
        trainee={trainee}
        product={product}
        learningType={selectedLearningType}
        requestTrainingNotification={requestTrainingNotification}
        mentorName={mentorName}
        channelName={channelName}
      />
      <DisableRequestTrainingModal
        modalStatus={disableRequestTrainingModalStatus}
        setModalStatus={setDisableRequestTrainingModalStatus}
        disableRequestTrainingNotification={disableRequestTrainingNotification}
        productNotification={productNotificationToDisable}
      />
    </Grid>
  )
}

export default observer(TraineeDetailsTableHeader)
