import React, { FC, useEffect, useState } from 'react'
import TeamsApi from 'api/teams'
import ProtectedPage from 'components/organisms/ProtectedPage/ProtectedPage'
import Text from 'components/atoms/Text/Text'
import { Grid, makeStyles } from '@material-ui/core'
import MaterialInput from 'components/atoms/MaterialInput/MaterialInput'
import useAbstractProvider from 'providers/AbstractProvider'
import Loader from 'components/atoms/Loader/Loader'
import MaterialTable from 'components/organisms/MaterialTable/MaterialTable'
import { useHistory, Link } from 'react-router-dom'
import { CustomButton } from 'components/atoms/MaterialButton/MaterialButton'
import AdminLayout from 'layouts/AdminLayout'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  heading: {
    marginBottom: 45,
  },
  saveBtn: {
    marginTop: 15,
  },
  search: {
    marginBottom: 15,
  },
  addTeam: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

interface AllTeamsPageProps {}

const AllTeamsPage: FC<AllTeamsPageProps> = () => {
  const classes = useStyles()
  const [data, setData] = useState([])
  const [search, setSearch] = useState('')
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)
  const history = useHistory()

  const { t } = useTranslation()

  const head = [
    { name: t('id'), dbName: 'id' },
    { name: t('name'), dbName: 'name' },
  ]

  const teams = useAbstractProvider(TeamsApi.getAllTeamsPagination, { search, offset, limit })

  useEffect(() => {
    if (teams?.data) {
      setData(teams?.data.data)
    }
  }, [teams.data])

  useEffect(() => {
    teams.refetch({ search, offset, limit })
  }, [search, offset, limit])

  function handleChangePage(pageToChange) {
    setOffset(pageToChange * limit)
  }

  const selectItem = (selectedItem) => {
    history.push(`/admin-view/team-detail/${selectedItem.id}`)
  }

  return (
    <ProtectedPage>
      {AdminLayout.getLayout(
        <>
          <Grid container>
            <Grid item xs={6}>
              <Text variant="h2" className={classes.heading}>
                {t('admin_users_and_teams.all_teams')}
              </Text>
            </Grid>
            <Grid item xs={6} className={classes.addTeam}>
              <Link to="/admin-view/add-team">
                <CustomButton onClick={() => {}}>
                  {t('admin_users_and_teams.add_team')}
                </CustomButton>
              </Link>
            </Grid>
            <Grid item xs={12}>
              <MaterialInput
                className={classes.search}
                type="text"
                label={t('search')}
                name="search"
                value={search}
                onChange={setSearch}
              />
            </Grid>

            <Grid item xs={12}>
              {teams.loading ? (
                <Loader />
              ) : (
                <div>
                  <MaterialTable
                    head={head}
                    data={data}
                    onRowClick={selectItem}
                    count={teams?.data?.count}
                    onChangePage={handleChangePage}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={limit}
                    onChangeRowsPerPage={(value) => {
                      setLimit(value)
                      setOffset(0)
                    }}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </>,
      )}
    </ProtectedPage>
  )
}

export default AllTeamsPage
